<script lang="ts" setup>
interface Props {
    pending?: boolean;
    error?: Error | null;
    errorMessage?: string;
}

const props = withDefaults(defineProps<Props>(), {
  pending: false,
  error: null,
  errorMessage: 'Не удалось загрузить данные, попробуйте позже',
})
</script>

<template>
    <Spinner :active="props.pending" />

    <template v-if="!pending">
        <Alert v-if="props.error">
            {{ props.errorMessage }}
        </Alert>

        <template v-else>
            <slot />
        </template>
    </template>
</template>

<style scoped></style>
